import React from 'react';
import { View } from 'react-navi';
import { lazy, mount, compose, withView } from 'navi';
import ReactGA from 'react-ga';
import TopApp from './top';
import InterviewApp from './interview';
import EntriesApp from './entries';
import ProfileApp from './profiles';
import PostsApp from './posts';

const apps = compose(
  withView((request: any) => {
    if (process.env.REACT_APP_CONTEXT === 'production') {
      ReactGA.pageview(request.path);
    }
    return <>
        <div className="is-misscircle cpck-window cpck-banner cpck-type-info cpck-theme-block cpck-bottom">
            <span id="cupcakeconsent:desc" className="cpck-message"><strong className="has-text-weight-bold">大学・専門学生募集中！</strong><br />早期エントリーであなたの夢を応援する特典動画をGET!</span>
            <div className="cpck-compliance">
                <a aria-label="dismiss cookie message"
                    href="https://entry.miscolle.com/gateway?utm_source=misscircle2020&utm_medium=operation"
                    className="cpck-btn cpck-dismiss"><span>💎</span>エントリー方法を確認する</a>
            </div>
        </div>
        <View />
    </>;
  }),
  mount({
    '/': TopApp,
    '/entries': EntriesApp,
    '/profiles': ProfileApp,
    '/interview': InterviewApp,
    '/privacy': lazy(() => import('./privacy')),
    '/entryterms': lazy(() => import('./entryterms')),
    '/posts': PostsApp,
  }),
);

export default apps;
